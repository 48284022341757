<template>
  <div class="v4-news-detail-container" v-if="detail">
    <div class="news-header-container">
      <div class="header__publish scrollObj">News {{ detail.detail_publish }}</div>
      <div class="header__title scrollObj">
        <p>
          {{ detail.title.en }}
        </p>
        <p>
          {{ detail.title.cn }}
        </p>
      </div>
    </div>
    <div class="news-detail-main-container">
      <div class="news-big-show-container scrollObj">
        <img :src="detail.cover.inner" alt="" />
      </div>
      <div class="news-detail-main-content">
        <div
          class="scrollObj"
          v-for="(content, index) in detail.content"
          :key="index"
          :class="{
            text: content.type === 'textarea',
            img: content.type === 'img'
          }"
          v-html="renderHtml(content)"
        ></div>
      </div>
      <div class="news-direction-bts">
        <div
          class="left bt scrollObj"
          data-extend="fixedshow"
          data-rate="0.3"
          v-if="prev"
          @click="onGo(prev)"
        >
          <p>previous</p>
          <img :src="prev.cover.outter" alt="" />
        </div>
        <div
          class="right bt scrollObj"
          data-extend="fixedshow"
          data-rate="0.3"
          v-if="next"
          @click="onGo(next)"
        >
          <p>next</p>
          <img :src="next.cover.outter" alt="" />
        </div>
      </div>
    </div>
    <!-- 前后新闻 -->
    <template v-if="$bowserMobile && (prev || next)">
      <div class="news-detail-more-container">
        <div class="more__inner">
          <div class="more__item scrollObj" @click="onGo(prev)">
            <template v-if="prev">
              <p>previous</p>
              <img :src="prev.cover.outter" alt="" class="img-cover" />
            </template>
          </div>
          <div class="more__item scrollObj" @click="onGo(next)">
            <template v-if="next">
              <p>next</p>
              <img :src="next.cover.outter" alt="" class="img-cover" />
            </template>
          </div>
        </div>
      </div>
    </template>
    <page-footer></page-footer>
    <div
      class="v4-back-bt scrollObj theme-b"
      data-extend="fixedshow"
      data-rate="0.8"
      @click="scrollToTop"
    >
      <p class="line"></p>
      <p>back top</p>
    </div>
  </div>
</template>

<script>
import { demo2OnScroll } from '@/assets/utils/common';
import { mapState } from 'vuex';
import pageFooter from '../common/page-footer.vue';

export default {
  name: 'v4-page-news-detail',
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    pageFooter,
  },
  data() {
    return {
      detail: null,
      next: null,
      prev: null,
    };
  },
  computed: {
    ...mapState(['$bowserMobile']),
  },
  watch: {
    $route: {
      handler() {
        this.$store.commit('setBackgroundColor', '#fff');
        document.body.style.cssText = 'background-color:#fff;';
        document.body.classList.remove('white-logo');
        this.detail = null;
        this.next = null;
        this.prev = null;
        this.getData();
      },
      immediate: true,
    },
  },
  methods: {
    scrollToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    getData() {
      const that = this;
      that.$fly.get(`/api/media/detail/${that.id}`).then((res) => {
        const { data } = res;
        that.detail = data.detail;
        that.next = data.next;
        that.prev = data.prev;
        that.$nextTick(() => {
          demo2OnScroll();
          document.title = that.detail.title.cn;
          document
            .querySelector('meta[name="keywords"]')
            .setAttribute('content', that.detail.summary);
          document
            .querySelector('meta[name="description"]')
            .setAttribute('content', that.detail.summary);
        });
      });
    },
    renderHtml(content) {
      return content.type === 'textarea' ? content.value : `<img src="${content.value}" />`;
    },
    onGo(data) {
      if (data) {
        this.$router.push({
          path: `/news/${data.id}`,
        });
      }
    },
  },
};
</script>

<style></style>
